import React, { useContext } from 'react'
import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SelectLanguage } from '../../ReuseComponents/SelectLanguage';
import { DrawerViewSurvey } from './DrawerViewSurvey';
import { SaveChangesViewSurvey } from './SaveChangesViewSurvey';

import StylesContext from './SytlesContextViewSurvey';
import { useIntl } from 'react-intl';
// import { PostSurveySettings } from '../PostSurveyComponents/PostSurveySettings';
import { ConfigAlerts } from '../ViewSurveyComponents/ConfigAlerts/ConfigAlerts';

export const NavbarViewSurvey = () => {
    const intl = useIntl();
    const {
        source
    } = useContext(StylesContext);
    const navigate = useNavigate();
    const [sourceName, setSourceName] = React.useState(source);

    //change the position of the app-navbar to fixed when the user scrolls down
    window.onscroll = function () { myFunction() };

    function myFunction() {
        if (document.getElementById("app-navbar")) {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                document.getElementById("app-navbar").style.position = "fixed";
                document.getElementById("app-navbar").style.top = "0";
                document.getElementById("app-navbar").style.width = "100%";
                document.getElementById("app-navbar").style.zIndex = "1201";
            } else {
                document.getElementById("app-navbar").style.position = "static";
            }
        }
    }

    return (
        <Box sx={{ flexGrow: 2 }}>
            <AppBar position="static" id="app-navbar">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <ArrowBackIcon style={{ color: "white" }} /> &nbsp;
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {/* <FormattedMessage
                            id="survey.edit.styles.title"
                        /> */}
                        <Tooltip title={intl.formatMessage({ id: "styles.url.name" })} >
                            <input
                                id="input-source"
                                className='sourceInput'
                                type="text"
                                style={{ color: "whitesmoke" }}
                                maxLength={25}
                                spellCheck="false"
                                value={sourceName}
                                onChange={(e) => setSourceName(e.target.value.toLowerCase())}
                            />
                        </Tooltip>
                        &nbsp;&nbsp;
                        <SelectLanguage styles={{ fontSize: "13px" }} />
                    </Typography>
                    <div>
                        {/* <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                <MenuItem onClick={handleClose}>My account</MenuItem>
                            </Menu> */}
                    </div>
                    <div >
                        <ConfigAlerts />
                    </div>
                    {/* <div >
                        <PostSurveySettings />
                    </div> */}
                    <div >
                        <DrawerViewSurvey />
                    </div>
                    <div >
                        <SaveChangesViewSurvey />
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
