import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../ReuseComponents/Footer";
import React from "react";
// import MainNavbar from "./mainNavbar";
import { FormattedMessage } from "react-intl";
import { MainFooter } from "../ReuseComponents/MainFooter";
import MetaDecorator from "../UtilsComponents/MetaDecorator";

export const Unauthorized = () => {
  document.body.style.backgroundColor = "#051B29";
  const navigate = useNavigate();

  return (
    <>
      <MetaDecorator
        title={"Not authorized - 7Bytes"}
        description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"}
        imageUrl={"/images/Oso.png"}
        imageAlt={"NotAuthorized"}
        path={window.location.pathname}
      />
      <section
        className="vh-100 d-flex justify-content-center align-items-center"
        style={{
          backgroundImage: "url(/images/background/connection.png)",
          backgroundSize: "cover"
        }}
      >
        <div className="container ">
          {/* <MainNavbar /> */}
          <div className="card" style={{ width: "auto", height: "auto" }}>
            <div className="card-body">
              <div>
                <img
                  className="img-fluid d-block mx-auto"
                  alt="Logo"
                  src="/images/7Bytes sin slogan.png"
                  style={{
                    width: "250px",
                    height: "auto",
                    padding: "1.5rem",
                  }}
                />
              </div>
              <div className="row text-center ">
                <div className="col-md ">
                  <div>
                    <img
                      className="img-fluid d-block mx-auto"
                      alt="Unauthorized"
                      src="/images/Oso.png"
                      style={{
                        width: "350px",
                        height: "auto",
                        padding: "2rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-md d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <h1
                      className="Title fw-bold mb-2"
                      style={{ fontFamily: "arial", fontSize: "50px" }}
                    >
                      403
                    </h1>
                    <h2
                      className="mb-3 "
                      style={{ fontFamily: "arial", fontSize: "35px" }}
                    >
                      <FormattedMessage
                        id="unauthorized.title"
                        defaultMessage="Acceso no autorizado"
                      />
                    </h2>
                    <h5
                      className="mb-4"
                      style={{ fontFamily: "arial", fontSize: "20px" }}
                    >
                      <FormattedMessage
                        id="unauthorized.description"
                      />
                    </h5>
                    <Link
                      className="btn btn-primary btn-lg "
                      tabIndex={-1}
                      role="button"
                      aria-disabled="true"
                      style={{
                        background: "#03989E",
                        fontFamily: "arial",
                        borderRadius: "20px",
                      }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <strong>
                        <FormattedMessage
                          id="back.home.button"
                          defaultMessage="Volver al inicio"
                        />
                      </strong>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <table>
                  <tbody>
                    <tr>
                      <Footer />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MainFooter />
    </>
  );
};
