import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Footer } from "../../ReuseComponents/Footer";
import * as Sentry from "@sentry/browser";
import template from "../../../Domains/customers.json";
import { FormattedMessage, useIntl } from "react-intl";
import { Particle } from "../../ReuseComponents/Particle";
import axios from "../../../api/axios";
import MetaDecorator from "../../UtilsComponents/MetaDecorator";
import { useQuery } from "@tanstack/react-query";
import { useSessionStorage } from "../../../CustomHooks/useSessionStorage";

export function NewSurvey() {
    let [id] = useState(useParams().id);
    const intl = useIntl();
    let [customer] = useState(useParams().customer);
    customer = customer.toLowerCase();
    const [server] = useState(process.env.REACT_APP_SERVER);
    const queryParams = new URLSearchParams(window.location.search);
    const number = queryParams.get("number");
    const Cname = queryParams.get("name");
    // const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [waitMsg, setWaitMsg] = useState("");
    const [surveyNumber, setSurveyNumber] = useSessionStorage("surveyNumber", 0);
    const [countdown, setCountdown] = useState(10 * surveyNumber);
    const [surveyFound, setSurveyFound] = useState(false);
    const [values, setValues] = useState({
        display_name: id.toLowerCase() === "7bytesdemo" ? Cname : null,
        phone: id.toLowerCase() === "7bytesdemo" ? number : null,
        email: null,
        survey_id: null,
        identification_number: null,
        status: 3,
        store: null,
        city: null,
        sent_method: "WEB"
    });
    // Validate customer

    const { url, logo, fontColor } = template["7bytes"];
    const headerBackground = "#051B29";
    const bodyBackground = "#051B29";
    // change the color of the body background
    document.body.style.backgroundColor = bodyBackground;

    const getCustomerData = async () => {
        const response = await axios.get(`customer/${id}?customer=${customer}`);
        if (!response.data?.text) {
            return response.data;
        }
    }

    const { data: customers, isLoading: isLoadingCustomer, error: customerError } = useQuery({
        queryKey: [customer, id],
        queryFn: getCustomerData,
        onError: (error) => {
            if (error.response?.status === 404) {
                // console.error("No se encontró el cliente");
                setErrorMsg(intl.formatMessage({ id: "survey.not.found.msg" }));
            } else if (error.response?.status === 409) {
                setErrorMsg(intl.formatMessage({ id: "survey.end.trial.msg" }));
            }
            else {
                console.error(error.message);
            }
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const getCustomerStoreData = async () => {
        const response = await axios.get(`store/${id}?customer=${customer}`);
        if (!response.data?.text) {
            const store = response.data;
            const capitalizeFirstLetter = str => (str && str.split(" ").map(subStr => subStr[0].toUpperCase() + subStr.slice(1)).join(" ")) || "";
            let obj = {};
            if (store) {
                setValues(v => ({
                    ...v,
                    store: store[0]?.store_code,
                    store_id: store[0]?.store_id,
                    city: capitalizeFirstLetter(store[0]?.city.toLowerCase()),
                    survey_id: store[0]?.survey_id
                }));
                obj[store[0]?.customerName] = response.data[0]?.survey_id;
            }
            return obj;
        }
    }

    const { data: customerStore, isLoading: isLoadingCustomerStore } = useQuery({
        queryKey: [`${customer}Store`, id],
        queryFn: getCustomerStoreData,
        onError: (error) => {
            if (error.response?.status === 404) {
                // console.error("No se encontró el cliente");
                setErrorMsg(intl.formatMessage({ id: "survey.not.found.msg" }));
            } else if (error.response?.status === 409) {
                setErrorMsg(intl.formatMessage({ id: "survey.end.trial.msg" }));
            }
            else {
                console.error(error.message);
            }
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    useEffect(() => {
        setIsLoading(isLoadingCustomer || isLoadingCustomerStore);
    }, [isLoadingCustomer, isLoadingCustomerStore]);

    useEffect(() => {
        if (customers) {
            if (customer !== "hermeco") {
                if (Object.keys(customers).length > 0) {
                    setValues(v => ({
                        ...v,
                        survey_id: customers[Object.keys(customers)[0]][0]
                    }));
                }
            }
        }
    }, [customers, customer, id]);

    useEffect(() => {
        if (countdown === 0 || isLoading || !surveyFound) return;
        if (surveyNumber > 0) {
            setWaitMsg(
                <FormattedMessage
                    id="new.survey.wait.msg"
                    defaultMessage={'Por favor espere unos segundos para continuar'}
                    values={{ countdown }}
                />
            );
            const timer = setTimeout(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000); // Decrease the countdown every 1000ms (1 second)
            // Clean up the timer when the component is unmounted or countdown reaches 0
            return () => clearTimeout(timer);
        }
    }, [surveyNumber, countdown, isLoading, surveyFound]);


    useEffect(() => {
        setSurveyFound(customerStore || customers);
    }, [customers, customerStore]);

    useEffect(() => {
        if (surveyFound && countdown === 0) {
            setSurveyNumber(parseInt(surveyNumber) + 1);
            const customerName = customers ? Object.keys(customers)[0] : Object.keys(customerStore)[0];
            fetch(`${server}users?customer=${customerName}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(values)
            }).then(async (res) => {
                const data = await res.json();
                //Send to main survey
                if (data?.id) {
                    window.location.href = `/mainsurvey/${customerName}/${data.id}`;
                } else {
                    setWaitMsg(data.text)
                }
            }).catch((err) => {
                console.error(err);
                Sentry.captureException(err);
            });
        }
    }, [customer, server, values, surveyFound, countdown]);

    return (
        <>
            <Particle />
            <MetaDecorator
                title={`NS | ${customer} | ${id}`}
                description={"Para nosotros es muy importante conocer tu opinión sobre nuestros productos y servicios. Por favor, ayúdanos a mejorar respondiendo esta encuesta."}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"7Bytes"}
                path={window.location.pathname}
            />
            <table border="0" cellPadding="0" cellSpacing="0" width="100%" >
                <tbody>
                    <tr>
                        <td bgcolor={headerBackground} align="center">
                            <table className="tableWith">
                                <tbody>
                                    <tr>
                                        <td className="td1" align="center" valign="top">
                                            <a target="_blank" rel="noreferrer" href={url}>
                                                <img className="img1 Login" alt="Logo" src={logo} width="40" height="40" border="0" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td className="td2" bgcolor={headerBackground}  >
                            <table className="tableWith" align="center"  >
                                <tbody>
                                    <tr>
                                        <td className="tdTitle Login" align="center">
                                            {isLoading ?
                                                <h1 style={{ color: fontColor }} className="Title mb-3">
                                                    <FormattedMessage
                                                        id="loading.msg"
                                                        defaultMessage={'Cargando...'}
                                                    />
                                                </h1>
                                                :
                                                surveyFound ?
                                                    <h1 style={{ color: fontColor }} className="Title mb-3">
                                                        <FormattedMessage
                                                            id="wait.msg"
                                                            defaultMessage={'Por favor espere'}
                                                        />
                                                    </h1>
                                                    :
                                                    <h1 className="Title mb-3" style={{ color: "#c4142b" }}> <strong>Error</strong> </h1>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr >
                        <td className="tdBody" bgcolor={bodyBackground} align="center" >
                            <table className="tableWith shadow2" >
                                <tbody>
                                    <tr>
                                        <td className="tdBody2 Login" bgcolor={"#ffffff"} align="center">
                                            {isLoading ? (
                                                <>
                                                    <span className="loader mb-3">
                                                    </span>
                                                    <div align="center">
                                                        {
                                                            waitMsg !== "" && <h3 style={{ color: fontColor }}>{waitMsg}</h3>
                                                        }
                                                    </div>
                                                </>
                                            )
                                                :
                                                (
                                                    surveyFound ?
                                                        countdown === 0 ?
                                                            <h2 style={{ color: fontColor }}>
                                                                <FormattedMessage
                                                                    id="redirecting.msg"
                                                                    defaultMessage={'Redireccionando...'}
                                                                />
                                                            </h2> :
                                                            <h2 style={{ color: fontColor }}>
                                                                {waitMsg}
                                                            </h2>
                                                        :
                                                        customerError?.response?.status === 409 ?
                                                            <>
                                                                <h3 style={{ color: "#c4142b" }}>{errorMsg}</h3>
                                                                <h3 style={{ color: "#c4142b" }}>
                                                                    <FormattedMessage
                                                                        id="survey.more.info.msg"
                                                                    /> &nbsp;
                                                                    <Link to="/contact" style={{ color: "#3a46c9" }}>
                                                                        <FormattedMessage
                                                                            id="survey.contact.us.msg"
                                                                        />
                                                                    </Link>
                                                                </h3>
                                                            </>
                                                            :
                                                            <h2 style={{ color: "#c4142b" }}>
                                                                {errorMsg}
                                                            </h2>
                                                )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <br />
                        </td>
                    </tr>
                    <tr >
                        <Footer bodyBackground={bodyBackground} />
                    </tr>
                </tbody>
            </table >
        </>
    )
}