import { FormattedMessage } from "react-intl";
import { Link, NavLink } from "react-router-dom";
import { Routes } from "../../Rutas/Routes";
import { SelectLanguage } from "./SelectLanguage";
import useLocalStorage from "../../CustomHooks/useLocalStorage";

export const Navbar = () => {
    const fontColor = "whitesmoke";
    const routes = Routes
    const [isLogin] = useLocalStorage('isLogin', false);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div className="container">
                <Link className="navbar-brand" to="/"><img src="/images/7Bytes sin slogan.png" alt="logo 7bytes" /></Link>
                <div className="nav-wrapper">
                    <SelectLanguage />
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars ms-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                        <>
                            {routes.map((route, i) => (
                                !route?.protected &&
                                <li className="nav-item" key={i} >
                                    <NavLink className="nav-link" to={route.path} style={({ isActive }) => ({ color: isActive ? "#119E94" : fontColor })}>
                                        {route.name}
                                    </NavLink>
                                </li>
                            ))}
                            {
                                isLogin ?
                                    <li className="nav-item">
                                        <Link style={{ color: fontColor }} className="nav-link btn btn-primary btn-sm text-uppercase login-btn" to="/dashboard">
                                            <i className="fa fa-user"></i> &nbsp;
                                            <FormattedMessage
                                                id="dashboard.title"
                                                defaultMessage="Dashboard"
                                            />
                                        </Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link style={{ color: fontColor }} className="nav-link btn btn-primary btn-sm text-uppercase login-btn" to="/dashboard">
                                            <i className="fa fa-user"></i> <FormattedMessage id="navbar.login" defaultMessage="iniciar sesión" />
                                        </Link>
                                    </li>
                            }
                        </>
                    </ul>
                </div>
            </div>
        </nav>
    );
}