import { Navigate, Route, Routes } from "react-router-dom"
import { Home } from "../components/LandingComponents/Home"
import { AboutUs } from "../components/LandingComponents/AboutUs"
import { Contact } from "../components/LandingComponents/Contact"
import { DataPolicy } from "../components/LandingComponents/DataPolicy"
import { MainSurvey } from "../components/SurveyComponents/CreateSurveyComponents/MainSurvey"
import { NewSurvey } from "../components/SurveyComponents/CreateSurveyComponents/NewSurvey"
import { NotFound } from "../components/ErrorComponents/NotFound"
import { Survey } from "../components/SurveyComponents/Survey"
import { UserForm } from "../components/SurveyComponents/CreateSurveyComponents/UserForm"
// import { Login } from "../components/AuthComponents/Login"
import { RequireAuth } from "../components/UtilsComponents/RequireAuth"
import { PersistLogin } from "../components/UtilsComponents/PersistLogin"
import useAuth from "../CustomHooks/useAuth"
import { Users } from "../components/UserComponents/Users"
import { Unauthorized } from "../components/ErrorComponents/Unauthorized"
// import { routes, protedtedRoutes } from "./allRoutes"
import { CreateUser } from "../components/UserComponents/CreateUser"
import { CreateCustomer } from "../components/CustomerComponents/CreateCustomer"
import useNetworkStatus from "../CustomHooks/useNetworkStatus"
import { FormattedMessage } from "react-intl"
import { CustomerStyles } from "../components/CustomerComponents/customerStyles"
import { SurveyPreview } from "../components/SurveyComponents/UtilsComponents/SurveyPreview"
import { Chart } from "../components/ReuseComponents/Chart"
import { Sidebar } from "../components/ReuseComponents/Sidebar"
import { MySurveysTable } from "../components/SurveyComponents/ViewSurveyComponents/MySurveysTable"
import { Settings } from "../components/ReuseComponents/Settings"
import { Communication } from "../components/CommunicationComponents/Communication"
import { AuthTemplate } from "../components/AuthComponents/AuthTemplate"
import { ViewSurvey } from "../components/SurveyComponents/ViewSurveyComponents/ViewSurvey"
import { MyStoresTable } from "../components/StoreComponents/MyStoresTable"
import { DashboardProvider } from "../components/DashboardComponents.js/context/DashboardProvider"
import { ROLES_LIST } from "../Domains/roleList"
import { ForgotPassword } from "../components/AuthComponents/ForgotPassword"
import { ResetPassword } from "../components/AuthComponents/ResetPassword"

const Rutas = () => {
    const { persist, isLogin } = useAuth();
    const onlineStatus = useNetworkStatus();
    const ViewsElements = [
        <Route key="/viewsurvey" exact path="/viewsurvey" element={<ViewSurvey />} />,
        <Route key="/dashboard" exact path="/dashboard" element={<Sidebar element={<DashboardProvider><Chart /></DashboardProvider>} />} />,
        <Route key="/dashboard/settings" exact path="/dashboard/settings" element={<Sidebar element={<Settings />} />} />,
        <Route key="/dashboard/mysurveys" exact path="/dashboard/mysurveys" element={<Sidebar element={<MySurveysTable />} />} />,
        <Route key="/dashboard/mystores" exact path="/dashboard/mystores" element={<Sidebar element={<MyStoresTable />} />} />
    ];
    const EditorElements = [
        ...ViewsElements,
        <Route key="/dashboard/customerstyles" exact path="/dashboard/customerstyles" element={<Sidebar element={<CustomerStyles />} />} />,
    ];
    const CustomerAdminElements = [
        ...EditorElements,
        <Route key="/dashboard/users" exact path="/dashboard/users" element={<Sidebar element={<Users />} />} />,
        <Route key="/dashboard/createuser" exact path="/dashboard/createuser" element={<Sidebar element={<CreateUser />} />} />,
        <Route key="/dashboard/createcustomer" exact path="/dashboard/createcustomer" element={<Sidebar element={<CreateCustomer />} />} />,
        <Route key="/dashboard/communications" exact path="/dashboard/communications" element={<Sidebar element={<Communication />} />} />,
    ];

    return (
        <>
            {!onlineStatus &&
                <div className="alert alert-danger alertConnection" role="alert">
                    <FormattedMessage
                        id="online.status.msg"
                        defaultMessage={`Estas sin conexión`}
                    />
                </div>
            }
            <Routes>
                {/* {routes.map((route, index) => (
                protedtedRoutes.filter((r) => r.path === route.path).length > 0 ?
                    <Route key={"p" + index} element={<RequireAuth allowedRoles={protedtedRoutes.find((r) => r.path === route.path)?.allowedRoles} />}>
                        <Route key={"pc" + index} exact path={route.path} element={route.element} />
                    </Route>
                    :
                    <Route key={index} exact path={route.path} element={route.element} />
            ))} */}

                {/* Se coloco asi porque cuando le doy click a unos que no esta dentro del persist y vuelvo y le doy click al que esta fuera del persist
                Vuele a cargar el componente con el nuevo token
            */}
                {persist ?
                    <Route element={<PersistLogin />} >
                        {/* El orden importa */}
                        {/* VIEW */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.View, ROLES_LIST.Admin, ROLES_LIST.customer_admin, ROLES_LIST.Editor]} />}>
                            {ViewsElements}
                        </Route>
                        {/* EDITOR */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Editor, ROLES_LIST.Admin, ROLES_LIST.customer_admin]} />}>
                            {EditorElements}
                        </Route>
                        {/* CUSTOMER_ADMIN */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin, ROLES_LIST.customer_admin]} />}>
                            {CustomerAdminElements}
                        </Route>
                    </Route>
                    :
                    <>
                        {/* El orden importa */}
                        {/* VIEW */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.View, ROLES_LIST.Admin, ROLES_LIST.customer_admin, ROLES_LIST.Editor]} />}>
                            {ViewsElements}
                        </Route>
                        {/* EDITOR */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Editor, ROLES_LIST.Admin, ROLES_LIST.customer_admin]} />}>
                            {EditorElements}
                        </Route>
                        {/* CUSTOMER_ADMIN */}
                        <Route element={<RequireAuth allowedRoles={[ROLES_LIST.Admin, ROLES_LIST.customer_admin]} />}>
                            {CustomerAdminElements}
                        </Route>
                    </>
                }
                <Route exact path="/" element={<Home />} />
                <Route exact path="/aboutUs" element={<AboutUs />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/dataPolicy" element={<DataPolicy />} />
                <Route exact path="/unauthorized" element={<Unauthorized />} />
                {!isLogin ? <Route exact path="/login" element={<AuthTemplate />} /> : <Route exact path="/login" element={<Navigate to="/" />}></Route>}
                <Route exact path="/register" element={<AuthTemplate />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/reset-password/:token" element={<ResetPassword />} />

                <Route exact path="/mainsurvey/:customer/:id" element={<MainSurvey />} />
                <Route exact path="/survey/:customer/:id" element={<Survey />} />
                <Route exact path="/user/:customer/:id" element={<UserForm />} />
                <Route exact path="/newsurvey/:customer/:id" element={<NewSurvey />} />
                <Route exact path="/surveypreview" element={<SurveyPreview />} />


                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    )
}

export default Rutas
