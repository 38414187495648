import { Navbar } from "../ReuseComponents/Navbar";
import { MainFooter } from "../ReuseComponents/MainFooter";
import { FormattedMessage, useIntl } from "react-intl";
import MetaDecorator from "../UtilsComponents/MetaDecorator";

export const AboutUs = () => {
    const backgroundColor = "#82CCCF";
    // document.body.style.backgroundColor = "#f4f4f4";
    const intl = useIntl();
    return (
        <>
            {/*<!-- Navigation-->*/}
            <Navbar />
            <MetaDecorator
                title={intl.formatMessage({ id: "aboutUs.title" })}
                description={intl.formatMessage({ id: "aboutUs.description" }).substring(0, 170)}
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                imageUrl={"/images/7Bytes.png"}
                path={window.location.pathname}
            />
            {/*<!-- Masthead-->*/}
            <header className="masthead" id="About us">
                <div className="container">
                    <div className="masthead-heading">
                        <FormattedMessage
                            id="aboutUs.title"
                            defaultMessage={`Quienes somos`}
                        />
                    </div>
                </div>
            </header>
            {/*<!-- Services-->*/}
            {/*<!-- Team-->*/}
            <section className="page-section bg-light" id="about">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-5">
                            <img src="/images/7Bytes.png" alt="..." width="80%" />
                            <br></br><br></br><br></br>
                        </div>
                        <div className="col-lg-7">
                            <div className="team-member" style={{ textAlign: "left" }}>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="aboutUs.description"
                                    />
                                </p>
                                <h4 style={{ color: "#119E94" }}>
                                    <FormattedMessage
                                        id="aboutUs.mission"
                                        defaultMessage={`Misión`}
                                    />
                                </h4>
                                <br></br>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="aboutUs.mission.description"
                                    />
                                </p>
                                <h4 style={{ color: "#119E94" }}>
                                    <FormattedMessage
                                        id="aboutUs.vision"
                                        defaultMessage={`Visión`}
                                    />
                                </h4>
                                <br></br>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="aboutUs.vision.description"
                                    />
                                </p>
                                <h4 style={{ color: "#119E94" }}>
                                    <FormattedMessage
                                        id="aboutUs.values"
                                        defaultMessage={`Valores`}
                                    />
                                </h4>
                                <br></br>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="aboutUs.values.description"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center">

                            <h3 style={{ color: "#119E94" }}>
                                <FormattedMessage
                                    id="aboutUs.teamWork"
                                    defaultMessage={`Trabajo en Equipo`}
                                />
                            </h3>
                            <div className="" style={{ color: backgroundColor }}>
                                <i className="fa fa-users fa-6x " />
                            </div>
                            <br />
                            {/* <img width="90%" src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/group-of-people-working-out-business-plan-in-an-office-1.jpg?resize=768%2C512&ssl=1" alt="..." /> */}
                            <p className="text-muted-white" style={{ textAlign: "left" }}>
                                <FormattedMessage
                                    id="aboutUs.teamWork.description"
                                />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">

                            <h3 style={{ color: "#119E94" }}>
                                <FormattedMessage
                                    id="aboutUs.commitment"
                                    defaultMessage={`Compromiso`}
                                />
                            </h3>
                            <div className="text-center" style={{ color: backgroundColor }}>
                                <i className="fa fa-hands-helping fa-6x" />
                            </div>
                            <br />
                            {/* <img width="90%" src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/business-partners-handshaking-at-group-meeting-making-project-investment-closeup.jpg?resize=768%2C512&ssl=1" alt="..." /> */}
                            <p className="text-muted-white" style={{ textAlign: "left" }}>
                                <FormattedMessage
                                    id="aboutUs.commitment.description"
                                />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <h3 style={{ color: "#119E94" }}>
                                <FormattedMessage
                                    id="aboutUs.innovation"
                                    defaultMessage={`Innovación`}
                                />
                            </h3>
                            <div className="text-center" style={{ color: backgroundColor }}>
                                <i className="fa fa-lightbulb fa-6x" />
                            </div>
                            <br />
                            {/* <img width="90%" src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/business-share-and-planing-strategy-brainstroming-concept.jpg?resize=768%2C512&ssl=1" alt="..." /> */}
                            <p className="text-muted-white" style={{ textAlign: "left" }}>
                                <FormattedMessage
                                    id="aboutUs.innovation.description"
                                />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <h3 style={{ color: "#119E94" }}>
                                <FormattedMessage
                                    id="aboutUs.agility"
                                    defaultMessage={`Agilidad`}
                                />
                            </h3>
                            <div className="text-center" style={{ color: backgroundColor }}>
                                <i className="fa fa-tachometer-alt fa-6x" />
                            </div>
                            <br />
                            {/* <img width="90%" src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/group-of-people-working-out-business-plan-in-an-office-2-1.jpg?resize=768%2C512&ssl=1" alt="..." /> */}
                            <p className="text-muted-white" style={{ textAlign: "left" }}>
                                <FormattedMessage
                                    id="aboutUs.agility.description"
                                />
                            </p>
                        </div>
                    </div>
                    <br></br><br></br>
                    <div className="row">
                        <h3 className="text-muted-white" style={{ textAlign: "center" }}>
                            <FormattedMessage
                                id="aboutUs.bottom.text"
                            />
                        </h3>
                    </div>
                </div>
            </section>
            <MainFooter backColor="#051B29" />
        </>
    );
}