import { Navbar } from "../ReuseComponents/Navbar";
import { MainFooter } from "../ReuseComponents/MainFooter";
import { FormattedMessage } from "react-intl";
import { languageContext } from "../../context/languageContext";
import { useContext } from "react";
import MetaDecorator from "../UtilsComponents/MetaDecorator";
import { ContactDemo } from "../ReuseComponents/ContactDemo";

export const Home = () => {
    // const backColor = "#82CCCF";
    const backgroundColor = "#82CCCF";
    const language = useContext(languageContext);

    return (
        <>
            {/*<!-- Navigation-->*/}
            <Navbar />
            {/*<!-- Masthead-->*/}
            
            <MetaDecorator 
                title={"7Bytes - Estudio de mercado continuo"} 
                description={"Entendiendo al consumidor de una forma. Amigable Efectiva y Relevante"} 
                imageUrl={"/images/7Bytes.png"} 
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                path={window.location.pathname}
            />
            <header className="masthead" id="services">
                <div className="container">
                    <div className="masthead-heading ">
                        <FormattedMessage
                            id="home.welcome"
                            defaultMessage={`Bienvenido a 7Bytes`}
                        />
                    </div>
                    <div className="masthead-subheading">
                        <FormattedMessage
                            id="home.description"
                            defaultMessage={`Vive la experiencia del estudio de mercado continuo`}
                        />
                    </div>
                    <br></br>
                    <a className="btn btn-primary btn-xl text-uppercase" href="#about">
                        <FormattedMessage
                            id="home.button.description"
                            defaultMessage={`Conoce más`}
                        />
                    </a>
                    <br></br><br></br><br></br><br></br><br></br>
                </div>
            </header>
            {/*<!-- Services-->*/}
            {/*<!-- Team-->*/}
            <section className="page-section bg-light" id="about">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase" style={{ fontSize: "37px", fontFamily: "'Roboto Slab','Times New Roman',serif" }}>
                            <FormattedMessage
                                id="home.section2.title"
                                defaultMessage={`¿Qué está ocurriendo en el mercado?`}
                            />
                        </h2>
                        <br></br>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="text-center" >
                                <div className="icon" style={{ color: backgroundColor }}>
                                    <i className="fa fa-envelope fa-4x" ></i>
                                </div>
                                <h4 className="mt-1">
                                    <FormattedMessage
                                        id="home.section2.titleItem1"
                                        defaultMessage={`Masividad`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item1.description"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text-center">
                                <div className="icon" style={{ color: backgroundColor }}>
                                    <i className="fa fa-globe fa-4x" ></i>
                                </div>
                                <h4 className="mt-2">
                                    <FormattedMessage
                                        id="home.section2.titleItem2"
                                        defaultMessage={`Profundidad`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item2.description"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="text-center">
                                <div className="icon" style={{ color: backgroundColor }}>
                                    <i className="fa fa-hourglass fa-4x" ></i>
                                </div>
                                <h4 className="mt-2">
                                    <FormattedMessage
                                        id="home.section2.titleItem3"
                                        defaultMessage={`Tiempo real`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item3.description"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <br></br><br></br><br></br>
                    <div className="text-center">
                        <h2 className="section-heading" style={{ fontSize: "37px", fontFamily: "'Roboto Slab','Times New Roman',serif" }}>
                            <FormattedMessage
                                id="home.section2.subTitle"
                                defaultMessage={`En 7Bytes integramos`}
                            />
                        </h2>
                        <br></br>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="team-member">
                                <div className="img2">
                                    <img style={{ width: "90px", height: "auto" }} src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/encuesta.png?fit=512%2C512&ssl=1" alt="..." />
                                </div>
                                <h4 style={{ fontSize: "22px" }}>
                                    <FormattedMessage
                                        id="home.section2.titleItem4"
                                        defaultMessage={`Encuestas personalizadas`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item4.description"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="team-member">
                                <div className="img2">
                                    <img style={{ width: "90px", height: "auto" }} src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/analisis-de-datos.png?fit=512%2C512&ssl=1" alt="..." />
                                </div>
                                <h4 style={{ fontSize: "22px" }}>
                                    <FormattedMessage
                                        id="home.section2.titleItem5"
                                        defaultMessage={`Análisis continuo`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item5.description"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="team-member">
                                <div className="img2">
                                    <img style={{ width: "90px", height: "auto" }} src="https://i0.wp.com/7bytesms.com/wp-content/uploads/2022/03/inteligencia-artificial.png?w=512&ssl=1" alt="..." />
                                </div>
                                <h4 style={{ fontSize: "22px" }}>
                                    <FormattedMessage
                                        id="home.section2.titleItem6"
                                        defaultMessage={`Inteligencia de Negocio`}
                                    />
                                </h4>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="home.section2.item6.description"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- Videos-->*/}
            <section className="page-section" id="hire">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="team-member" >
                                <h4 style={{ textAlign: "left", fontSize: "28px" }}>
                                    <FormattedMessage
                                        id="home.section3.textTitle"
                                        defaultMessage={`Por qué contratar 7Bytes`}
                                    />
                                </h4>
                                <p className="text-muted mt-3" >
                                    <FormattedMessage
                                        id="home.section3.textDescription"
                                    />
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="team-member" >
                                <br></br><br></br>
                                <a className="btn btn-primary btn-xl text-uppercase" href="#AgendarDemoHome" >
                                    <FormattedMessage
                                        id="demo.button"
                                        defaultMessage={`Agendar demo`}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    {language.locale === "en" ?
                        <>
                            <div className="text-center">
                                <h2 className="section-heading" >
                                    Live the 7bytes experience
                                </h2>
                                <iframe width="100%" height="700" src="https://www.youtube.com/embed/NnrfxrJouX8" title="Live the 7bytes experience" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <br /><br />
                        </>
                        :
                        <>
                            <div className="text-center">
                                <h2 className="section-heading" >
                                    <FormattedMessage
                                        id="home.section3.Title2"
                                        defaultMessage={`Casos de Éxito 7Bytes`}
                                    />
                                </h2>
                                <iframe width="100%" height="700" src="https://www.youtube.com/embed/PjEXuPbTBsk" title="Caso de éxito: OFFCORSS | 7Bytes" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <br /><br />
                            <div className="row">
                                <div className="col-lg-7">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/a65sY5VCT2s" title="Conoce a 7Bytes: Estudio de mercado continuo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                            </div>
                                <div className="col-lg-5">
                                    <br /><br /><br /><br /><br />
                                    <h1 align="center">
                                        <FormattedMessage
                                            id="home.section3.Title3"
                                            defaultMessage={`El estudio de mercado nunca fue tan sencillo`}
                                        />
                                    </h1>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
            {/*<!-- Clients-->*/}
            <section className="page-section" id="clients" style={{ padding: "20px" }}>
                <h2 className="section-heading text-center text-uppercase">
                    <FormattedMessage
                        id="home.section4.Title"
                        defaultMessage={`Clientes`}
                    />
                </h2>
                <div className="py-2" >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://www.offcorss.com/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/hermeco.png" alt="..." loading="lazy" aria-label="hermeco" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://www.boamar.com.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/boamar.png" alt="..." loading="lazy" aria-label="boamar" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://www.lilipink.com/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/lilipink.png" alt="..." loading="lazy" aria-label="lilipink" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://www.cristalup.com/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/cristalup.webp" alt="..." loading="lazy" aria-label="cristalup" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://www.esprit.com.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/esprit.png" alt="..." loading="lazy" aria-label="esprit" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://wajaca.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/wajaka.jpeg" alt="..." loading="lazy" aria-label="wajaka" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://ilforno.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/ilforno.png" alt="..." loading="lazy" aria-label="ilforno" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://pintuco.com.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/pintuco.webp" alt="..." loading="lazy" aria-label="pintuco" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://veracup.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/Vera.webp" alt="..." loading="lazy" aria-label="Vera" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://multicar.com.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/multicar.png" alt="..." loading="lazy" aria-label="multicar" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://masbell.com/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/masbell.png" alt="..." loading="lazy" aria-label="masbell" /></a>
                            </div>
                            <div className="col-md-3 col-sm-6 my-3">
                                <a href="https://estratek.com.co/" target="_blank" rel="noreferrer"><img className="img-fluid img-brand d-block mx-auto" src="images/logos/estratek.png" alt="..." loading="lazy" aria-label="estratek" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- Contact-->*/}
            <section className="page-section" id="contact">
                <div className="container">
                    <br></br><br></br><br></br>
                    <div className="row" style={{ justifyContent: "center" }}>
                        <div className="col-lg-5">
                            <div className="team-member" >
                                <h2>
                                    <FormattedMessage
                                        id="home.section5.Title1"
                                        defaultMessage={`Comience con una Demo personalizada`}
                                    />
                                </h2>
                                <p className="text-muted">
                                    <FormattedMessage
                                        id="home.section5.description1"
                                    />
                                </p>
                                <br></br><br></br>
                                <h2>
                                    <FormattedMessage
                                        id="home.section5.Title2"
                                        defaultMessage={`Ubicación`}
                                    />
                                </h2>
                                <p className="text-muted">
                                    <FormattedMessage
                                        id="home.section5.description2"
                                        defaultMessage={`Medellín, Colombia`}
                                    />
                                </p>
                                <br></br><br></br>
                                <h2>
                                    <FormattedMessage
                                        id="home.section5.Title3"
                                        defaultMessage={`Contacto a Whatsapp`}
                                    />
                                </h2>
                                <p className="text-muted">
                                    <a href="https://wa.link/d9qeok" target="_blank" rel="noreferrer" style={{ color: "#adb5bd" }}>
                                        <FormattedMessage
                                            id="cellphone.number"
                                        />
                                    </a>
                                </p>
                            </div>
                            <br></br><br></br>
                        </div>
                        <div className="col-lg-7">
                            <div className="team-member" style={{ padding: "10px 8% 80px 5%" }} >
                                <div classnamename="tdBody2" bgcolor="white" align="center" style={{ borderRadius: "20px", backgroundColor: "whitesmoke", color: "#212529" }}>
                                    <ContactDemo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*<!-- Footer-->*/}
            <MainFooter backColor="#051B29" />
        </>
    );
}