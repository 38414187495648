import React from 'react'
import { Particle } from '../ReuseComponents/Particle'
import { Login } from './Login'
import { Register } from './Register'

export const AuthTemplate = () => {
    //get path from url
    const path = window.location.pathname
    return (
        <>
            <Particle />
            {path === '/login' ? <Login /> : <Register />}
        </>
    )
}
