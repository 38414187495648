import { Navbar } from "../ReuseComponents/Navbar";
import { MainFooter } from "../ReuseComponents/MainFooter";
import MetaDecorator from "../UtilsComponents/MetaDecorator";

export const DataPolicy = () => {
    // document.body.style.backgroundColor = "#f4f4f4";

    return (
        <>
            {/*<!-- Navigation-->*/}
            <Navbar />
            <MetaDecorator 
                title={"Política de datos"} 
                description={"Establecer las políticas y procedimientos de OCXCI S.A.S para garantizar el adecuado cumplimiento de la Ley 1581 de 2012 y el Decreto 1377 de 2013"} 
                imageUrl={"/images/7Bytes.png"} 
                imageAlt={"Política de datos"}
                path={window.location.pathname}
            />
            <section className="page-section bg-light" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <h1>Políticas de Tratamiento de Información</h1>
                            <br></br><br></br><br></br>
                            <p align="justify" style={{ lineHeight: "220%" }}>
                                <strong>Objetivo</strong><br></br>
                                Establecer las políticas y procedimientos de OCXCI S.A.S &#40;en adelante la “EMPRESA”,
                                “nosotros”, “nos”&#41; para garantizar el adecuado cumplimiento de la Ley 1581 de 2012 y el Decreto
                                1377 de 2013, por las cuales se desarrolla el derecho constitucional que tienen todas las personas
                                a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bases de
                                datos, y los demás derechos, libertades y garantías constitucionales a que se refiere el artículo 15
                                de la Constitución Política; así como el derecho a la información consagrado en el artículo 20 de
                                la misma.<br></br>
                                La EMPRESA, teniendo en cuenta su condición de Responsable del Tratamiento de Datos, define
                                las siguiente políticas donde se establecen los aspectos relacionados con la autorización del Titular
                                de la información para el Tratamiento de sus datos personales, las políticas de Tratamiento de los
                                Responsables y Encargados, el ejercicio de los derechos de los Titulares de información, las
                                transferencias de datos personales y la responsabilidad demostrada frente al Tratamiento de datos
                                personales.<br></br><br></br>
                                <strong>Alcance</strong><br></br>
                                Las presentes políticas aplican a todos los empleados de OCXCI S.A.S, los aspirantes a ser
                                contratados por la misma, exempleados, representantes, agencias y a
                                todas las sedes y sucursales a nivel nacional que tengan cualquier actividad relacionada con el
                                tratamiento de datos personales; incluye la información de datos personales de clientes, empleados,
                                proveedores, contratistas, aliados estratégicos, vinculados y socios.<br></br><br></br>
                                <strong>Definiciones</strong><br></br>
                                ● Titular: Cada una de las personas naturales que haya suministrado sus datos personales.<br></br>
                                ● Datos Personales: Conjunto de información susceptible de relacionarse a una o más
                                personas naturales.<br></br>
                                ● Datos Sensibles: Se entiende por datos sensibles aquellos que afectan la intimidad del
                                Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que
                                revelen el origen racial o étnico, la orientación política, las convicciones religiosas o
                                filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o
                                que promueva intereses de cualquier partido político o que garanticen los derechos y
                                garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la
                                vida sexual y los datos biométricos.<br></br>
                                ● Tratamiento de Datos &#40;o “Tratamiento”&#41;: Cualquier operación o conjunto de
                                operaciones sobre datos personales, tales como la recolección, almacenamiento, uso,
                                circulación o supresión.<br></br>
                                ● Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
                                misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.<br></br>
                                ● Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí
                                misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.<br></br>
                                ● Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.<br></br>
                                Principios<br></br>
                                OCXCI S.A.S está comprometida con la implementación de mecanismos relacionados con la
                                información personal de terceros para garantizar, de manera armónica e integral, los siguientes
                                principios:<br></br>
                                ● Principio de legalidad en materia de Tratamiento de datos: El Tratamiento a que se refiere
                                la presente ley es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás
                                disposiciones que la desarrollen;<br></br>
                                ● Principio de finalidad: El Tratamiento debe obedecer a una finalidad legítima de acuerdo con
                                la Constitución y la Ley, la cual debe ser informada al Titular;<br></br>
                                ● Principio de libertad: El Tratamiento solo puede ejercerse con el consentimiento, previo,
                                expreso e informado del Titular. Los datos personales no podrán ser obtenidos o divulgados sin
                                previa autorización, o en ausencia de mandato legal o judicial que releve el consentimiento;<br></br>
                                ● Principio de veracidad o calidad: La información sujeta a Tratamiento debe ser veraz,
                                completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el Tratamiento de datos
                                parciales, incompletos, fraccionados o que induzcan a error;<br></br>
                                ● Principio de transparencia: En el tratamiento debe garantizarse el derecho del Titular a obtener
                                del Responsable del Tratamiento o del Encargado del Tratamiento, en cualquier momento y sin
                                restricciones, información acerca de la existencia de datos que le conciernan;<br></br>
                                ● Principio de acceso y circulación restringida: El Tratamiento se sujeta a los límites que se
                                derivan de la naturaleza de los datos personales, de las disposiciones de la presente ley y la
                                Constitución. En este sentido, el Tratamiento sólo podrá hacerse por personas autorizadas por el
                                Titular y/o por las personas previstas en la presente ley; Los datos personales, salvo la información
                                pública, no podrán estar disponibles en Internet u otros medios de divulgación o comunicación
                                masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido
                                sólo a los Titulares o terceros autorizados conforme a la presente ley;<br></br>
                                ● Principio de seguridad: La información sujeta a Tratamiento por el Responsable del
                                Tratamiento o Encargado del Tratamiento a que se refiere la presente ley, se deberá manejar con
                                las medidas técnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los
                                registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;<br></br>
                                ● Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de datos
                                personales que no tengan la naturaleza de públicos están
                                obligadas a garantizar la reserva de la información, inclusive después de finalizada su relación con
                                alguna de las labores que comprende el Tratamiento, pudiendo sólo realizar suministro o
                                comunicación de datos personales cuando ello corresponda al desarrollo de las actividades
                                autorizadas en la presente ley y en los términos de la misma.<br></br><br></br>
                                <strong>Autorización del Titular para el Tratamiento de Datos</strong><br></br>
                                OCXCI S.A.S requiere del consentimiento libre, previo, expreso e informado del Titular de los
                                datos personales para el tratamiento de los mismos, excepto en los casos
                                expresamente autorizados en la ley. Dicha autorización será otorgada por: el Titular, quien deberá
                                acreditar su identidad en forma suficiente, según las condiciones indicadas por la Empresa; o los
                                causahabientes, acudientes, representantes o apoderados del titular, quienes deberán acreditar tal
                                calidad, representación o apoderamiento.<br></br>
                                La EMPRESA obtendrá la autorización mediante diferentes medios, entre ellos: el documento
                                físico, correo electrónico, página web, aplicación o en cualquier otro formato que permita
                                evidenciar que se divulgó la Política de Tratamiento de Información y que el titular autoriza el
                                Tratamiento de sus datos personales.<br></br>
                                La EMPRESA podrá entregar la información de los Titulares de los Datos a: los siguientes:<br></br>
                                ● A los Titulares de los datos, sus herederos o representantes en cualquier momento y a
                                través de cualquier medio cuando así lo soliciten a la EMPRESA.<br></br>
                                ● A las entidades judiciales o administrativas en ejercicio de funciones que eleven algún
                                requerimiento a la EMPRESA para que le sea entregada la información.<br></br>
                                ● A los terceros que sean autorizados por alguna ley de la república de Colombia.<br></br>
                                ● A los terceros a los que el Titular de los Datos autorice expresamente para entregar la
                                información y cuya autorización sea entregada a la EMPRESA.<br></br>
                                La autorización del Titular no será necesaria en los siguientes casos:<br></br>
                                ● Información requerida por una entidad pública o administrativa en ejercicio de sus funciones
                                legales o por orden judicial.<br></br>
                                ● Datos de naturaleza pública.<br></br>
                                ● Casos de urgencia médica o sanitaria.<br></br>
                                ● Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.<br></br>
                                ● Datos relacionados con el Registro Civil de las Personas.<br></br>
                                Los titulares de los datos personales pueden en cualquier momento revocar la autorización
                                otorgada a la EMPRESA para el Tratamiento de sus datos personales o
                                solicitar la supresión de los mismos, siempre y cuando no lo impida una disposición legal o
                                contractual.<br></br><br></br>
                                <strong>Finalidades y Usos de los Datos Personales</strong><br></br>
                                OCXCI S.A.S en el desarrollo de su objeto social y sus relaciones con empleados, clientes,
                                proveedores, acreedores, aliados estratégicos, filiales, distribuidores, entre
                                otros, recolecta datos que se limitan a aquellos datos personales que son pertinentes y adecuados
                                para las siguientes finalidades:<br></br>
                                ● Desarrollar actividades administrativas, de recursos humanos, nómina y de gestión interna de la
                                EMPRESA.<br></br>
                                ● Establecer la información consolidada y actualizada de los clientes.<br></br>
                                ● Desarrollar actividades comerciales, promocionales, publicitarias e informativas.<br></br>
                                ● Crear un conocimiento cercano de sus clientes y terceros para actividades de segmentación,
                                mercadeo y ventas.<br></br>
                                ● Contactar a los Titulares para monitorear y evaluar la calidad de nuestros productos y servicios.<br></br>
                                ● Desarrollar actividades de gestión de proveedores, entre ellos la facturación, gestión comercial
                                y cobros.<br></br>
                                ● Realizar actividades relacionadas con Programas de Aceleración, eventos o Concursos
                                relacionados con el Emprendimiento.<br></br>
                                ● Publicar en medios o redes sociales información relacionada con Programas de Aceleración,
                                eventos o Concursos relacionados con el Emprendimiento y los
                                participantes que se hayan inscrito mediante o través de OCXCI S.A.S.<br></br>
                                ● Actividades de mercadeo y comunicaciones como: Promocionar eventos, concursos, convocatorias y demás actividades relacionadas
                                con la promoción y posicionamiento de marca de Estratek S.A.S en cualquiera de los medios de Estratek S.A.S., como
                                físico, digital o audiovisual.<br></br>
                                En relación con las anteriores finalidades, la EMPRESA podrá:<br></br>
                                ● Obtener, almacenar, compilar, intercambiar, actualizar, recolectar, procesar, reproducir y/o
                                disponer de los datos o información parcial o total de los Titulares
                                de los datos personales.<br></br>
                                ● Clasificar, ordenar y segmentar la información suministrada por el Titular de los datos.<br></br>
                                ● Comparar, verificar y validar los datos que obtenga en debida forma con centrales de riesgo
                                crediticio con las cuales se tengan relaciones comerciales.<br></br>
                                ● Extender la información que obtenga, en los términos de la Ley, a las empresas con las que
                                contrata los servicios de recolección, almacenamiento, manejo y Tratamiento de sus Bases de
                                Datos previas las debidas autorizaciones y requerimientos legales que en ese sentido sean
                                necesarias.<br></br>
                                ● Transferir los datos o información parcial o total a sus filiales, comercios, empresas y/o entidades
                                afiliadas y aliados estratégicos.<br></br><br></br>
                                <strong>Derechos de los Titulares de los Datos</strong><br></br>
                                Conocer, consultar, actualizar y rectificar sus datos personales en el momento en que los considere
                                parciales, inexactos, incompletos, fraccionados y modificar o
                                eliminar aquellos que induzcan a error o cuyo tratamiento está expresamente prohibido o no haya
                                sido autorizado.<br></br>
                                ● Solicitar en cualquier momento una prueba de la autorización otorgada a OCXCI S.A.S<br></br>
                                ● Ser informado por la EMPRESA del uso que le ha dado a Datos Personales, previa solicitud.<br></br>
                                ● Consultar los mecanismos de control y seguridad dispuestos y solicitar información específica
                                relacionada.<br></br>
                                ● Solicitar la supresión de algún dato y/o revocar la autorización cuando considere que la
                                EMPRESA no ha respetado sus derechos y garantías constitucionales, o
                                simplemente cuando desee hacerlo.<br></br>
                                ● Presentar ante la Superintendencia de Industria y Comercio las quejas que considere pertinentes
                                para hacer valer su derecho al Habeas Data frente a la Empresa.<br></br><br></br>
                                <strong>En relación con las anteriores finalidades, la EMPRESA podrá:</strong><br></br>
                                ● Obtener, almacenar, compilar, intercambiar, actualizar, recolectar, procesar, reproducir y/o
                                disponer de los datos o información parcial o total de los Titulares
                                de los datos personales.<br></br>
                                ● Clasificar, ordenar y segmentar la información suministrada por el Titular de los datos.<br></br>
                                ● Comparar, verificar y validar los datos que obtenga en debida forma con centrales de riesgo
                                crediticio con las cuales se tengan relaciones comerciales.<br></br>
                                ● Extender la información que obtenga, en los términos de la Ley, a las empresas con las que
                                contrata los servicios de recolección, almacenamiento, manejo y Tratamiento de sus Bases de
                                Datos previas las debidas autorizaciones y requerimientos legales que en ese sentido sean
                                necesarias.<br></br>
                                ● Transferir los datos o información parcial o total a sus filiales, comercios, empresas y/o entidades
                                afiliadas y aliados estratégicos.<br></br><br></br>
                                <strong>Deberes de la EMPRESA como responsable del Tratamiento de datos personales</strong><br></br>
                                OCXCI S.A.S reconoce que los Datos Personales son propiedad de sus Titulares y que únicamente
                                ellos podrán decidir sobre estos. De acuerdo con lo anterior, la
                                EMPRESA asume los siguientes deberes en su calidad de Responsable del Tratamiento:<br></br>
                                ● Contar con el canal para obtener la autorización expresa por parte del Titular para realizar
                                cualquier tipo de tratamiento de datos.<br></br>
                                ● Solicitar y conservar, en las condiciones previstas en la Ley de Protección de Datos Personales,
                                copia de la respectiva autorización otorgada por el Titular.<br></br>
                                ● Informar de manera clara y expresa a Titulares de los Datos el Tratamiento al cual serán
                                sometidos los mismos y la Finalidad de dicho Tratamiento por virtud de
                                la autorización otorgada.<br></br>
                                ● Garantizar al Titular de la información, en todo tiempo, el pleno y efectivo ejercicio del derecho
                                de hábeas data.<br></br>
                                ● Suministrar al Encargado del Tratamiento, según el caso, únicamente datos cuyo tratamiento
                                este previamente autorizado de conformidad con lo previsto en la Ley de Datos Personales.<br></br>
                                ● Informar los derechos que le asisten a todos los Titulares respecto a sus datos.<br></br>
                                ● Mantener y velar por la seguridad de los registros de Datos Personales almacenados para impedir
                                su deterioro, pérdida, alteración, uso no autorizado o fraudulento.<br></br>
                                ● Realizar periódica y oportunamente la actualización y rectificación de los datos, cada vez que
                                los Titulares de los mismos le reporten novedades o solicitudes.<br></br>
                                ● Informar la identificación, dirección física y/o electrónica y teléfono de la persona o área que
                                tendrá la calidad de Responsable del Tratamiento.<br></br>
                                ● En caso de presentarse cambios sustanciales en el contenido de esta Política de Tratamiento de
                                Información que puedan afectar el contenido de la autorización
                                que el Titular le ha otorgado a la EMPRESA, esta le comunicará oportunamente y de manera
                                eficiente los cambios antes de o a más tardar al momento de
                                implementación de las nuevas políticas.<br></br><br></br>
                                <strong>Atención a consultas, peticiones, quejas y reclamos</strong><br></br>
                                Todo titular o causahabiente de Datos Personales tiene derecho de forma gratuita a realizar
                                consultas y solicitudes a la EMPRESA para conocer, actualizar, rectificar,
                                suprimir información y revocar la autorización o para realizar peticiones, quejas y reclamos
                                respecto al Tratamiento que OCXCI S.A.S da a la información.<br></br>
                                Los Titulares pueden contactarse con el área Administrativa responsable de la atención de
                                peticiones, quejas y reclamos a través del correo electrónico info@estratek.com.co.<br></br>
                                La consulta debe estar dirigida a nombre del Área Administrativa, con el nombre completo del
                                Titular, la descripción de la consulta, solicitud, petición, queja o reclamo, la dirección de
                                residencia, el teléfono de contacto y el correo electrónico. El titular de la información deberá
                                presentar y/o adjuntar los siguientes documentos:<br></br>
                                ● Si se trata del Titular: Documento de identidad válido.<br></br>
                                ● Si se trata del causahabiente: Documento de identidad válido, Registro Civil de Defunción del
                                Titular, Documento que acredite la calidad en que actúa y el
                                número del documento de identidad del Titular.<br></br>
                                ● Si se trata de un representante legal y/o apoderado: Documento de identidad válido, Documento
                                que acredite la calidad de Representante legal y/o apoderado del titular y el número del documento
                                de identidad del Titular.<br></br><br></br>
                                En caso de que la consulta resulte incompleta, la EMPRESA solicitará al interesado para que subsane las
                                fallas dentro de los cinco &#40;5&#41; días siguientes a la recepción del reclamo. <br></br>
                                Transcurridos dos meses desde la fecha del requerimiento, sin que el solicitante presente la
                                información requerida, se entenderá que ha desistido de la reclamación o petición.<br></br>
                                La consulta será atendida en un término máximo de diez &#40;10&#41; días hábiles contados a partir de la
                                fecha de radicación. Cuando no fuere posible atender la consulta dentro de dicho término, se
                                informarán los motivos de la demora, y la fecha en que se atenderá su consulta, la cual en ningún
                                caso podrá superar los cinco &#40;5&#41; días hábiles siguientes alvencimiento del primer término.<br></br>
                                Revocatoria de la autorización y/o supresión del dato<br></br>
                                Todo titular o causahabiente de Datos Personales tiene derecho a solicitar a OCXCI S.A.S la
                                eliminación total o parcial de sus Datos Personales. Para ello se seguirá el procedimiento
                                establecido en el punto anterior de este documento.<br></br>
                                La supresión de Datos operará y será definitiva siempre y cuando los mismos: &#40;a&#41; no estén siendo
                                tratados conforme a lo establecido por la legislación vigente, &#40;b&#41; hayan dejado de ser necesarios
                                para la finalidad con la cual se recaudaron o, &#40;c&#41; se haya superado el periodo de tiempo requerido
                                para cumplir con el fin por el cual se recaudaron.<br></br>
                                La EMPRESA podrá negar la eliminación cuando: &#40;a&#41; El Titular tenga el deber legal y/o
                                contractual de permanecer en la base de datos; &#40;b&#41; La supresión de los datos obstaculice
                                actuaciones judiciales o administrativas en curso.<br></br><br></br>
                                <strong>Privacidad de la información</strong><br></br>
                                OCXCI S.A.S está comprometida con proteger la información de los Titulares. Por lo tanto respeta
                                y protege los datos personales de acuerdo a las leyes vigentes de
                                Colombia y normas que regulan el manejo y la privacidad de la información y protección de datos,
                                Ley 1266 de 2008, la cual van en concordancia con el orden público.<br></br>
                                Vigencia<br></br>
                                Las presentes Políticas de Tratamiento de la Información entran en vigencia a partir de abril 25 de
                                2016 y las Bases de Datos que contienen la información de los Titulares tendrán una vigencia de
                                10 años prorrogables por períodos iguales.<br></br><br></br>
                                Responsable del Tratamiento<br></br>
                                OCXCI S.A.S<br></br>
                                Medellín<br></br><br></br>
                            </p>
                        </div>
                    </div>

                </div>
            </section>

            {/*<!-- Footer-->*/}
            <MainFooter backColor="#051B29" />
        </>
    );
}