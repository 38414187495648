import { Navbar } from "../ReuseComponents/Navbar";
import { MainFooter } from "../ReuseComponents/MainFooter";
import { FormattedMessage, useIntl } from "react-intl";
import MetaDecorator from "../UtilsComponents/MetaDecorator";
import { ContactDemo } from "../ReuseComponents/ContactDemo";

export const Contact = () => {
    // document.body.style.backgroundColor = "#f4f4f4";
    const intl = useIntl();

    return (
        <>
            {/*<!-- Navigation-->*/}
            <Navbar />
            <MetaDecorator 
                title={intl.formatMessage({ id: "navbar.contact" })}
                description={intl.formatMessage({ id: "contact.subTitle1.description" }).substring(0, 170)}
                imageUrl={"/images/7Bytes.png"} 
                imageAlt={"7Bytes - Estudio de mercado continuo"}
                path={window.location.pathname}
            />
            {/*<!-- Masthead-->*/}
            <header className="masthead" id="Contacto">
                <div className="container">
                    <div className="masthead-heading">
                        <FormattedMessage
                            id="navbar.contact"
                            defaultMessage="Contacto"
                        />
                    </div>
                </div>
            </header>
            {/*<!-- Services-->*/}
            <section className="page-section bg-light" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="team-member" style={{ textAlign: "left" }}>
                                <h3 style={{ color: "#119E94" }}>
                                    <FormattedMessage
                                        id="contact.subTitle1"
                                        defaultMessage="Póngase en contacto con el equipo de ventas"
                                    />
                                </h3>
                                <br></br>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="contact.subTitle1.description"
                                    />
                                </p>
                                <h4>
                                    <FormattedMessage
                                        id="contact.subTitle2"
                                        defaultMessage="Encuentrenos en"
                                    />
                                </h4>
                                <br></br>
                                <p className="text-muted-white">
                                    <FormattedMessage
                                        id="contact.subTitle2.description"
                                        defaultMessage="Encuentrenos en"
                                    />
                                    <br /><br />
                                    <a href="https://wa.link/d9qeok" style={{ color: "#119E94" }} target="_blank" rel="noreferrer">
                                        <FormattedMessage
                                            id="cellphone.number"
                                        />
                                    </a>
                                </p>
                                <h4>
                                    <FormattedMessage
                                        id="contact.subTitle3"
                                        defaultMessage="Formato PQRS"
                                    />
                                </h4>
                                <br></br>
                                <p className="text-muted-white">
                                    <a href="https://7bytesms.com/wp-content/uploads/2022/03/FORMATO-DE-PETICIONES-QUEJAS-O-RECLAMOS.docx" rel="noreferrer" style={{ color: "#119E94", textDecoration: "underline" }}>
                                        <FormattedMessage
                                            id="contact.subTitle3.description"
                                            defaultMessage="FORMATO-DE-PETICIONES-QUEJAS-O-RECLAMOS"
                                        />
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="team-member">
                                <div classnamename="tdBody2" bgcolor="white" align="left" >
                                <ContactDemo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<!-- Footer-->*/}
            <MainFooter backColor="#051B29" />
        </>
    );
}