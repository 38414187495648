import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MetaDecorator from '../UtilsComponents/MetaDecorator'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SnackBarComponent } from '../UtilsComponents/SnackBarComponent';
import { topCenterPosition, TransitionDown } from '../UtilsComponents/SnackBarTransitions';
import Visibility from '@mui/icons-material/Visibility';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "../../api/axios";
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { languageContext } from '../../context/languageContext';
import AddIcon from '@mui/icons-material/Add';

// const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const REGISTER_URL = "register/all";

export const Register = () => {
    const language = useContext(languageContext);
    const intl = useIntl();
    const initialPwdCriteria = {
        letters: false,
        number: false,
        specialCharacter: false,
        capitalLetter: false,
        length: false
    }
    const [selectedCustomerType, setSelectedCustomerType] = useState('-');
    const [color, setColor] = useState("success");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [msg, setMsg] = useState('');

    const mailRef = React.useRef();
    const selectRef = React.useRef();
    const [urlName, setUrlName] = useState('');

    const [pwdCriteria, setPwdCriteria] = useState(initialPwdCriteria);
    const [pwd, setPwd] = useState('');
    const [verifyPwd, setVerifyPwd] = useState('');

    const [showPassword, setShowPassword] = React.useState(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);
    const [validPwd, setValidPwd] = useState();
    const [isSending, setIsSending] = useState(false);

    const [captchaToken, setCaptchaToken] = useState();
    const recaptchaRef = React.useRef();
    const specialCharacters = /[!@#ñ$%^&*()_+\-=[\]{};':"\\|,.<>/¡¿?]+/;

    const [otherCustomerType, setOtherCustomerType] = useState("");
    //TODO: USAR el error
    const { data: customerTypes, isLoading: isLoadingCustomerTypes, isError: isErrorCustomerTypes } = useQuery({
        queryKey: ["customerTypes"],
        queryFn: async () => {
            const response = await axios.get(`customertypes?language=${language.locale}`);
            return response.data
        },
        refetchOnWindowFocus: false,
        retry: 0,
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24
    });

    useEffect(() => {
        if (pwd.length >= 8 && pwd.length <= 24) {
            setPwdCriteria(p => ({ ...p, length: true }));
        } else {
            setPwdCriteria(p => ({ ...p, length: false }));
        }
        if (pwd.match(/[a-z]/g)) {
            setPwdCriteria(p => ({ ...p, letters: true }));
        } else {
            setPwdCriteria(p => ({ ...p, letters: false }));
        }
        if (pwd.match(/[A-Z]/g)) {
            setPwdCriteria(p => ({ ...p, capitalLetter: true }));
        } else {
            setPwdCriteria(p => ({ ...p, capitalLetter: false }));
        }
        if (pwd.match(/[0-9]/g)) {
            setPwdCriteria(p => ({ ...p, number: true }));
        } else {
            setPwdCriteria(p => ({ ...p, number: false }));
        }
        if (pwd.match(specialCharacters)) {
            setPwdCriteria(p => ({ ...p, specialCharacter: true }));
        } else {
            setPwdCriteria(p => ({ ...p, specialCharacter: false }));
        }
        // eslint-disable-next-line
    }, [pwd, verifyPwd]);

    const handleSelect = (e) => {
        const { value } = e.target;
        if (!isNaN(value)) {
            setSelectedCustomerType(value);
        } else {
            setSelectedCustomerType('-');
        }
    }

    const validations = () => {
        const tilde = /[áéíóúÁÉÍÓÚ]+/;
        if (!Object.values(pwdCriteria).every(c => c)) {
            setValidPwd(true);
            return (intl.formatMessage({ id: "register.invalid.pwd" }));
        } else if (pwd !== verifyPwd) {
            setValidPwd(false);
            return (intl.formatMessage({ id: "register.pwd.match" }));
        } else if (selectedCustomerType === "-") {
            return (intl.formatMessage({ id: "register.select.type.store" }));
        } else if (urlName?.length > 30) {
            return (intl.formatMessage({ id: "register.store.name.length" }));
        } else if (specialCharacters.test(urlName) || tilde.test(urlName)) {
            return (intl.formatMessage({ id: "register.store.name.special.char" }));
        } else if (mailRef.current.value?.length > 50) {
            return (intl.formatMessage({ id: "register.email.length" }));
        }
        else if (otherCustomerType?.length > 30) {
            return (intl.formatMessage({ id: "register.customer.type.other.length" }));
        }
        else if (!captchaToken) {
            return (intl.formatMessage({ id: "register.check.captcha" }));
        }
        setValidPwd(false);
    }

    function onChange(value) {
        setCaptchaToken(value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const getErrMsg = validations();
        if (getErrMsg) {
            setOpenSnackBar(true);
            setColor("error");
            return setMsg(getErrMsg);
        }
        const requestJson = {
            "email": mailRef.current.value,
            urlName: urlName.replace(/ /g, "").trim(),
            pwd,
            customerType: selectedCustomerType !== "-" ? selectedCustomerType : 1,
            otherCustomerType,
            "token": captchaToken,
            "from": "register"
        }
        try {
            setIsSending(true);
            const response = await axios.post(REGISTER_URL,
                JSON.stringify(requestJson),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            if (response.status === 200) {
                setOpenSnackBar(true);
                setColor("success");
                setMsg(intl.formatMessage({ id: "register.success" }));
                mailRef.current.value = "";
                setUrlName("");
                selectRef.current.selectedIndex = 0;
                setSelectedCustomerType("-");
                setPwd("");
                setVerifyPwd("");
            }
        } catch (err) {
            if (err.response?.status === 400) {
                if (err.response?.data?.text === "Captcha no validado") {
                    console.error(err);
                    setOpenSnackBar(true);
                    setColor("error");
                    setMsg(intl.formatMessage({ id: "error.captcha" }));
                }
            } if (err.response?.status === 409) {
                if (err.response?.data?.text === "El email ya existe") {
                    console.error(err);
                    setOpenSnackBar(true);
                    setColor("error");
                    setMsg(intl.formatMessage({ id: "register.error.email.exists" }));
                } else if (err.response?.data?.text === "El cliente ya existe") {
                    console.error(err);
                    setOpenSnackBar(true);
                    setColor("error");
                    setMsg(intl.formatMessage({ id: "register.error.url.customer.exists" }));
                }
            } else {
                setOpenSnackBar(true);
                setColor("error");
                setMsg(intl.formatMessage({ id: "register.error" }));
                console.error(err);
            }
        } finally {
            setIsSending(false);
            setCaptchaToken();
            recaptchaRef.current.reset();
        }
    }

    return (
        <section className=" py-2"
            style={{
                background: "Linear-gradient(45deg, #051B29, #09989E 80%)",
                minHeight: "100vh"
            }}>
            <MetaDecorator
                title={"Registrar"}
                description={"Bienvenido a 7Bytes, por favor registrese para poder acceder a la plataforma."}
                imageUrl={"/images/7Bytes.png"}
                imageAlt={"Registrar 7Bytes"}
                path={window.location.pathname}
            />
            <SnackBarComponent
                position={topCenterPosition}
                transition={TransitionDown}
                handleClose={(event, reason) => {
                    if (reason === 'clickaway') {
                        return;
                    }
                    setOpenSnackBar(false);
                }}
                time={5000}
                openSnackBar={openSnackBar}
                color={color}
                msg={msg}
            />
            <div className="container py-4 Login" >
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="row mt-3 signup-content" id="" style={{ margin: "" }}>
                        <div className="text-center" >
                            <h2 className="fw-normal "
                                style={{
                                    color: "#ffff",
                                    letterSpacing: 1,
                                    fontSize: 40
                                }}>
                                <strong>
                                    <FormattedMessage
                                        id="register"
                                    />
                                </strong>
                            </h2>
                        </div>
                        {/* ----------------------- */}
                        <div className="col-md-12 mt-3 align-items-center" >
                            <div className="text-black" >
                                <form onSubmit={handleSubmit}>
                                    <div className="row g-2 mb-3">
                                        <div className="col-md-7">
                                            <div className="form-floating mb-2">
                                                <input
                                                    ref={mailRef}
                                                    type="email"
                                                    className="form-control"
                                                    style={{ borderRadius: "10px" }}
                                                    id="inputemail"
                                                    autoComplete="off"
                                                    placeholder="Email"
                                                    required
                                                />
                                                <label htmlFor="floatingInputEmail">
                                                    <i className="fa fa-envelope" style={{ color: "gray" }} /> &nbsp;
                                                    <FormattedMessage
                                                        id="login.email"
                                                        defaultMessage={`Email`}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-floating ">
                                                <input
                                                    onChange={(e) => setUrlName(e.target.value)}
                                                    value={urlName}
                                                    type="text"
                                                    className="form-control"
                                                    style={{ borderRadius: "10px" }}
                                                    id="inputName"
                                                    autoComplete="off"
                                                    placeholder="Nombre"
                                                    required
                                                />
                                                <label htmlFor="inputName">
                                                    <StorefrontTwoToneIcon /> <FormattedMessage
                                                        id="register.business.name"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {!Object.values(pwdCriteria).every(c => c) && <div className="row g-3 mb-3">
                                        <div className="col-md">
                                            <div style={{ color: "whitesmoke" }}>
                                                <FormattedMessage
                                                    id="create.user.title.pwd"
                                                    defaultMessage={`Configurar la contraseña`}
                                                />
                                                <br />
                                                <FormattedMessage
                                                    id="create.user.subtitle.pwd"
                                                />
                                                <br />
                                                <ul>
                                                    <div className="row">
                                                        <div className="col-md">
                                                            <li style={{ color: pwdCriteria.length ? "lightgreen" : "whitesmoke" }}>
                                                                <FormattedMessage
                                                                    id="create.user.pwd.criteria.1"
                                                                />
                                                                &nbsp;
                                                                {pwdCriteria.length && <CheckCircleIcon style={{ color: "lightgreen" }} />}
                                                            </li>
                                                            <li style={{ color: pwdCriteria.letters ? "lightgreen" : "whitesmoke" }}>
                                                                <FormattedMessage
                                                                    id="create.user.pwd.criteria.2"
                                                                />&nbsp;
                                                                {pwdCriteria.letters && <CheckCircleIcon style={{ color: "lightgreen" }} />}
                                                            </li>
                                                            <li style={{ color: pwdCriteria.number ? "lightgreen" : "whitesmoke" }}>
                                                                <FormattedMessage
                                                                    id="create.user.pwd.criteria.3"
                                                                /> &nbsp;
                                                                {pwdCriteria.number && <CheckCircleIcon style={{ color: "lightgreen" }} />}
                                                            </li>
                                                        </div>
                                                        <div className="col-md">
                                                            <li style={{ color: pwdCriteria.capitalLetter ? "lightgreen" : "whitesmoke" }}>
                                                                <FormattedMessage
                                                                    id="create.user.pwd.criteria.4"
                                                                />&nbsp;
                                                                {pwdCriteria.capitalLetter && <CheckCircleIcon style={{ color: "lightgreen" }} />}
                                                            </li>
                                                            <li style={{ color: pwdCriteria.specialCharacter ? "lightgreen" : "whitesmoke" }}>
                                                                <FormattedMessage
                                                                    id="create.user.pwd.criteria.5"
                                                                />
                                                                &nbsp;
                                                                {pwdCriteria.specialCharacter && <CheckCircleIcon style={{ color: "lightgreen" }} />}
                                                            </li>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* Colocar que se puedan ver las contraseñas al momento de darle al boton de mirar */}
                                    <div className="row g-3 mb-3">
                                        <div className="col-md">
                                            <FormControl fullWidth sx={{ borderRadius: "10px", backgroundColor: "white" }} variant="filled">
                                                <InputLabel htmlFor="inputpassword" style={{ color: validPwd ? 'red' : '' }}>
                                                    <i className="fa fa-key" style={{ color: "gray" }} /> <FormattedMessage id="login.password" />
                                                </InputLabel>
                                                <FilledInput
                                                    error={validPwd}
                                                    style={{ color: validPwd ? 'red' : '', }}
                                                    onChange={(e) => setPwd(e.target.value)}
                                                    value={pwd}
                                                    required
                                                    id="inputpassword"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { setShowPassword(!showPassword) }}
                                                                onMouseDown={(e) => { e.preventDefault() }}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="col-md">
                                            <FormControl fullWidth sx={{ borderRadius: "10px", backgroundColor: "white" }} variant="filled">
                                                <InputLabel htmlFor="inputconfirmpassword" >
                                                    <i className="fa fa-key" style={{ color: "gray" }} /> <FormattedMessage id="create.user.verify.pwd" />
                                                </InputLabel>
                                                <FilledInput
                                                    onChange={(e) => setVerifyPwd(e.target.value)}
                                                    value={verifyPwd}
                                                    required
                                                    id="inputconfirmpassword"
                                                    type={showVerifyPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => { setShowVerifyPassword(!showVerifyPassword) }}
                                                                onMouseDown={(e) => { e.preventDefault() }}
                                                                edge="end"
                                                            >
                                                                {showVerifyPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="row g-3 mb-2">
                                        <div className="col-md">
                                            {!isLoadingCustomerTypes &&
                                                !isErrorCustomerTypes &&
                                                <div className="form-floating" >
                                                    <select className="form-select" ref={selectRef} style={{ borderRadius: "10px" }} onChange={handleSelect} id="SelectCustomer" aria-label="Floating label select example">
                                                        <option value="-">
                                                            {intl.formatMessage({ id: "register.business.type" })}
                                                        </option>
                                                        {customerTypes?.map((type, index) => (
                                                            <option key={index} value={type.id}>
                                                                {type.type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label htmlFor="SelectCustomer">
                                                        <FormattedMessage
                                                            id="register.business"
                                                        />
                                                    </label>
                                                </div>
                                            }
                                        </div>
                                        {
                                            selectedCustomerType === "0" &&
                                            <div className="col-md">
                                                <div className="form-floating mb-2">
                                                    <input
                                                        onChange={(e) => setOtherCustomerType(e.target.value)}
                                                        value={otherCustomerType}
                                                        type="text"
                                                        className="form-control"
                                                        style={{ borderRadius: "10px" }}
                                                        id="inputlinkname"
                                                        autoComplete="off"
                                                        placeholder="linkname"
                                                        required
                                                    />
                                                    <label htmlFor="inputlinkname">
                                                        <AddIcon /> <FormattedMessage id="register.customer.type.other.title" />
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        className='centerRecaptcha mt-3'
                                        sitekey="6Lfp7lYlAAAAAEy4aJUT2z7SOxEdaB_eC948HdXl"
                                        onChange={onChange}
                                        size="normal"
                                    />
                                    <div className="form-group">
                                        <div className='row'>
                                            <div className='col-md-9 mt-3'>
                                                <button type="submit" name="submit" disabled={isSending} id="submit" className="form-submit btn-danger" style={{ backgroundColor: "#0e8d87" }}>
                                                    {isSending ?
                                                        <FormattedMessage
                                                            id="creating.user.btn"
                                                            defaultMessage={`Creando...`}
                                                        />
                                                        :
                                                        <FormattedMessage
                                                            id="create.user.btn"
                                                            defaultMessage={`Crear`}
                                                        />
                                                    }
                                                </button>
                                            </div>
                                            <div className='col-md-3 mt-3'>
                                                <Link to={'/login'} type="submit" name="submit" disabled={isSending} id="submit" className="form-submit-small text-center btn-danger">
                                                    <FormattedMessage
                                                        id="settings.cancel.pass.btn"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}